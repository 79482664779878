.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--sSpasingMedium) var(--sSpasingLarge);
  border: none;
  background: none;
  color: var(--cTextInverse);
  border-radius: var(--radius);
  font-weight: 500;
  outline: none;
  font-size: inherit;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  cursor: pointer;
  text-decoration: none;
  --cLoaderColor: var(--cShade);
}

.button__content {
  display: flex;
  align-items: center;
}

.button:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  background-color: var(--cThemeLocal);
  opacity: 1;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  border-radius: var(--radius);
}

.button:disabled,
.button.button_disabled {
  cursor: not-allowed;
  color: var(--cTextLight);
}

.button:disabled:before,
.button.button_disabled:before {
  background-color: var(--cShade);
}

.button:not(:disabled):not(.button_disabled):hover:before,
.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: var(--cThemeLocalActive);
}

.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  box-shadow: 0 0 0 3px var(--cThemeLocal);
}

.button:not(:disabled):not(.button_disabled):active:before {
  transition-duration: calc(var(--tGeneric) / 4);
  background-color: var(--cThemeLocal);
  left: 2px;
  right: 2px;
}

.button__content > * {
  position: relative;
}

.button__icon:not(:last-child) {
  margin-right: 10px;
}

.button__icon svg {
  display: block;
}

.button_iconOnly {
  padding-right: 12px;
  padding-left: 12px;
}

.button_fullWidth {
  width: 100%;
}

.button_vertical {
  padding: var(--sSpasingLarge) var(--sSpasingMedium);
}

.button_vertical .button__content {
  flex-direction: column;
}

.button_vertical .button__icon:not(:last-child) {
  margin-right: 0;
}

/* Wireframe */

.button_wireframe {
  color: inherit;
}

.button_wireframe:before {
  background-color: transparent;
  border: 2px solid currentColor;
}

.button_wireframe.button_themeOutline:before {
  background-color: transparent;
  border: 2px solid var(--cThemeLocal);
}

.button_wireframe:disabled,
.button_wireframe.button_disabled {
  cursor: not-allowed;
  color: var(--cTextLight);
}

.button_wireframe:disabled:before,
.button_wireframe.button_disabled:before {
  background-color: var(--cShade);
}

.button_wireframe:not(:disabled):not(.button_disabled):hover:before,
.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: var(--cThemeLocalActive);
  border-color: var(--cThemeLocalActive);
}

.button_wireframe:not(:disabled):not(.button_disabled):hover,
.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible) {
  color: var(--cTextInverse);
}

.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  box-shadow: 0 0 0 3px var(--cThemeLocal);
}

.button_wireframe:not(:disabled):not(.button_disabled):active:before {
  transition-duration: calc(var(--tGeneric) / 4);
  background-color: var(--cThemeLocalActive);
  border-color: var(--cThemeLocalActive);
  color: var(--cTextInverse);
  left: 2px;
  right: 2px;
}

.button_wireframe:not(:disabled):not(.button_disabled):active {
  color: var(--cTextInverse);
}
