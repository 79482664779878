:root {
  font-family: "Roboto", sans-serif;

  --cSuccess: #72b01d;
  --cSuccessLight: #a4cb6d;
  --cTheme: #c72643;
  --cThemeLight: #d73754;
  --cThemeLightTransparent: rgba(208, 42, 73, 0.2);
  --cThemeLightActive: #d02a49;
  --cThemeActive: #b61b37;
  --cDanger: #e57373;
  --cDangerTransparent: rgba(229, 115, 115, 0.2);
  --cMainBg: #fff;
  --cShade: #efefef;
  --cShadeRoot: #efefef;
  --cShadeTransparent: hsla(0, 0%, 93.7%, 0.5);
  --cShadeActive: #e0e0e0;
  --cBorder: #dedede;
  --cTextLight: #757575;
  --cText: #2b2b2b;
  --cTextInverse: #fff;
  --border: #ddd;

  --cDietV: #2b8467;
  --cDietVActive: #186a50;
  --cDiet7: #2e7da4;
  --cDiet7Active: #246788;
  --cDiet12: #4468ae;
  --cDiet12Active: #314D85;
  --cDiet15: #5C5FA4;
  --cDiet15Active: #47457A;
  --cDiet18: #6A4A73;
  --cDiet18Active: #4A2D52;
  --cDiet25: #4F2F62;
  --cDiet25Active: #391C4A;
  --cDietK15: #EFAC2A;
  --cDietK15Active: #D08B06;
  --cDietK25: #EB7442;
  --cDietK25Active: #CD511B;

  --focusRing: 0 0 0 3px var(--cThemeLight);
  --dangerRing: 0 0 0 2px var(--cDanger);

  --radius: 10px;
  --radiusSmall: 7px;

  --tGeneric: 200ms;
  --tfEaseOut: cubic-bezier(0.1, 1, 0.3, 1);
  --tfStandart: cubic-bezier(0.4, 0, 0.2, 1);

  --sFontH1: 2rem; /*32*/
  --sFontH2: 1.5rem; /*24*/
  --sFontH3: 1.125rem; /*18*/

  --sFontBody: 1rem; /*16*/
  /*--sFontExtraBig: 1.429rem; !*20*!*/
  --sFontBig: 1.125rem; /*18*/
  /*--sFontSmall: 0.875rem; !*14*!*/

  --sSpasingTiny: 4px;
  --sSpasingSmall: 8px;
  --sSpasingMedium: 12px;
  --sSpasingLarge: 20px;

  --zAbove: 200;
  --zModal: 500;
}

@media (prefers-reduced-motion: reduce) {
  :root {
    --tGeneric: 0ms;
  }
}

.shadedBg > *,
.shaded > * {
  --cShade: var(--cMainBg);
  --cTextLight: #737373;
}

.standardBg > * {
  --cShade: #efefef;
  --cTextLight: #a5a5a5;
}
