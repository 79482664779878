.contentCard {
  box-sizing: border-box;
  padding: var(--sSpasingLarge);
  border-radius: var(--radius);
  background-color: var(--cMainBg);
  display: grid;
  grid-gap: var(--sSpasingLarge);
}

.contentCard__title {
}
