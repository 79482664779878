.dietCard {
  border-radius: var(--radius);
  overflow: hidden;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  display: flex;
  flex-direction: column;
}

.dietCard:hover,
.dietCard:focus-within {
  box-shadow: 0 0 0 2px var(--cThemeLocal);
}

.dietCard__body {
  background-color: var(--cMainBg);
  padding: var(--sSpasingLarge);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.dietCard__image {
  height: 127px;
  width: 100%;
  object-fit: cover;
  display: block;
}

.dietCard__container {
  margin-bottom: var(--sSpasingMedium);
  display: flex;
  flex-grow: 1;
}

.dietCard__content {
  margin-right: var(--sSpasingMedium);
}

.dietCard__title {
  margin-bottom: var(--sSpasingTiny);
}

.dietCard__description {
  color: var(--cTextLight);
}

.dietCard__price {
  margin-bottom: var(--sSpasingMedium);
}

.dietCard__priceLabel {
  margin-bottom: var(--sSpasingTiny);
}

.dietCard__priceValue {
  color: var(--cThemeLocal);
  font-weight: 500;
  font-size: var(--sFontBig);
}
