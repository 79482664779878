.deliveryCalendar {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(var(--numberOfRows, 5), auto);
  --gap: var(--sSpasingMedium);
  gap: var(--gap);
  padding: calc(var(--gap) / 2);
}

@media screen and (max-width: 540px) {
  .deliveryCalendar {
    --gap: var(--sSpasingSmall);
  }
}

.deliveryCalendar__deliveryOutline {
  grid-row: 1 / -1;
  grid-column: var(--currentColumn) / span 1;
  margin: calc(var(--gap) / -2);
  border-radius: var(--radiusSmall);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.deliveryCalendar__deliveryOutline:not(.deliveryCalendar__deliveryOutline_hoverZone) {
  border: 1px solid var(--cBorder);
}

.deliveryCalendar__deliveryOutline_hoverZone {
  z-index: 1;
}

.deliveryCalendar__deliveryOutline_hoverZone:hover
  + .deliveryCalendar__deliveryOutline {
  background-color: var(--cShade);
}

.deliveryCalendar__day {
  display: flex;
  gap: var(--sSpasingSmall);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  border: 2px solid transparent;
  border-radius: var(--radiusSmall);
  font-weight: 500;
}

.deliveryCalendar__day_today {
  border-color: var(--cThemeLocal);
}

.deliveryCalendar__day_included {
  background-color: var(--cThemeLocal);
  color: var(--cTextInverse);
}

.deliveryCalendar__day_header {
  font-weight: 600;
}

.deliveryCalendar__header {
  display: flex;
  gap: var(--sSpasingSmall);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

@media screen and (max-width: 540px) {
  .deliveryCalendar__header {
    flex-direction: column;
    justify-content: flex-end;
  }
}
